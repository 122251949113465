import { create } from 'zustand';
import { persistNSync } from 'persist-and-sync';
// @stores
import { uiStore, authStore } from 'hohm-store';
// @app
import { feathersClient } from '@app/client';

export type TStore = uiStore.TUIStore & authStore.TAuthStore;

export const useStore = create<TStore>()(
	persistNSync(
		(...a) => ({
			...uiStore.createUiStore(feathersClient)(...a),
			...authStore.createAuthStore(feathersClient)(...a),
		}),
		{ name: 'hohm-client-store' }
	)
);
