import { PropsWithChildren, useEffect, useState } from 'react';
import { useStore } from '@app/store';
import { LoadingScreen } from 'hohm-ui';

const WithCampaign = ({ children }: PropsWithChildren) => {
	const [loading, setLoading] = useState(true);
	const getCampaign = useStore((state) => state.getCampaign);

	useEffect(() => {
		try {
			const loadApp = async () => {
				await getCampaign();
				setLoading(false);
			};

			loadApp();
		} catch (err) {
			// could not load campaign, what do?
		}
	}, []);

	return (
		<LoadingScreen loading={loading} message="Loading campaign">
			{children}
		</LoadingScreen>
	);
};

export default WithCampaign;
