'use client';

import * as React from 'react';
import { useMount } from 'react-use';
// ui
import { LoadingScreen } from 'hohm-ui';
import { TReAuthenticateClientReturn } from 'hohm-queries';

interface ITemplateProps extends React.PropsWithChildren {
	reAuthenticateClient: () => TReAuthenticateClientReturn;
}

const Authenticate = ({ children, reAuthenticateClient }: ITemplateProps) => {
	const [loading, setLoading] = React.useState(true);

	useMount(() => {
		try {
			const authenticateUser = async () => {
				await reAuthenticateClient();

				// TO*DO - Reimplement fetch currencies
				// await store.currencies.fetchCurrencies({});

				setLoading(false);
			};

			authenticateUser();
		} catch (err) {
			// console.log('Error in Authenticate', err);
		}
	});

	return (
		<LoadingScreen loading={loading} message="Authenticating user">
			{children}
		</LoadingScreen>
	);
};

export default Authenticate;
