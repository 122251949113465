'use client';

import * as React from 'react';
// @mui
import { styled } from '@mui/material/styles';
// ui
import { Stack, Typography } from '@mui/material';
import { CircularProgress } from './CircularProgress';

export interface ILoadingScreenProps extends React.PropsWithChildren {
	loading: boolean;
	message?: string;
}

const LoadingScreenLayout = styled('div')(() => ({
	zIndex: 1000,
	position: 'fixed',
	backgroundColor: 'white',
	height: '100vh',
	width: '100vw',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const LoadingScreen = ({
	children,
	loading,
	message = '',
}: ILoadingScreenProps) => {
	if (loading && message) {
		return (
			<LoadingScreenLayout>
				<Stack direction="column" spacing={4} alignItems="center">
					<CircularProgress />
					<Typography variant="body1">{message} ...</Typography>
				</Stack>
			</LoadingScreenLayout>
		);
	}

	if (loading) {
		return (
			<LoadingScreenLayout>
				<CircularProgress />
			</LoadingScreenLayout>
		);
	}

	return children;
};
