'use client';

import { useEffect, useState, Dispatch, SetStateAction } from 'react';
// @mui
import { Button, Grid, Skeleton, Typography } from '@mui/material';
// @local
import { logger } from 'hohm-utilities';
import typesOfFiles from './variables';

export type TFileUploadProps = {
	upload: (
		newFile: File,
		setProgress: Dispatch<SetStateAction<number | null>>,
		fileName: string
	) => Promise<boolean>;
	url?: string;
	name: string;
	id?: string | null;
	showFile?: boolean;
	providedPreview?: string | null;
	disabled?: boolean;
	uploadOnce?: boolean;
	setSelectedFile?: Dispatch<SetStateAction<File | null>>;
	allowedFiles?: string;
	allowMultipleFiles?: boolean;
	disableSelectFile?: boolean;
};
export const FileUpload = ({
	upload,
	name,
	url,
	id,
	showFile = true,
	providedPreview,
	disabled = false,
	uploadOnce,
	setSelectedFile,
	allowedFiles,
	allowMultipleFiles = true,
	disableSelectFile = false,
}: TFileUploadProps) => {
	const [progress, setProgress] = useState<number | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [fileAfterUpload, setFileAfterUpload] = useState<File | null>(null);

	const fileNameViewTypes = ['application/pdf', 'application/vnd.ms-excel'];

	const allowedFileTypes = allowedFiles || typesOfFiles;

	useEffect(() => {
		if (url === '') {
			setFileAfterUpload(null);
			setFile(null);
			setProgress(null);
			setPreview(null);
		} else if (url) {
			setPreview(url);
		}
		logger.log('logo preview url', url);
	}, [url]);

	const uploadSelectedFile = async (newFile: File) => {
		const result = await upload(newFile, setProgress, name);

		if (result) {
			setFileAfterUpload(file || null);
			setFile(null);
			setProgress(null);
		}
	};

	const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		const newFile = e.target && e.target.files ? e.target.files[0] : null;

		if (newFile) {
			reader.onloadend = () => {
				setFile(newFile);

				if (setSelectedFile) {
					setSelectedFile(newFile);
				}

				setPreview(reader.result as string);
			};
			reader.readAsDataURL(newFile);
		}
	};

	return (
		<Grid container spacing={4}>
			{showFile ? (
				<Grid item md={5}>
					{preview ? (
						file !== null &&
						fileNameViewTypes.includes(file.type) ? (
							<Typography variant="overline">
								{file.name}
							</Typography>
						) : fileAfterUpload !== null &&
						  fileNameViewTypes.includes(fileAfterUpload.type) ? (
							<Typography variant="overline">
								{fileAfterUpload.name}
							</Typography>
						) : (
							<img
								src={preview}
								alt={name}
								style={{ height: '60px', width: '60px' }}
							/>
						)
					) : providedPreview ? (
						<img
							src={providedPreview}
							alt={name}
							style={{ height: '60px', width: '60px' }}
						/>
					) : (
						<Skeleton
							variant="rectangular"
							width="60px"
							height="60px"
						/>
					)}
				</Grid>
			) : null}
			<Grid item md={7}>
				{progress ? (
					<Typography variant="overline">{progress}%</Typography>
				) : (
					<>
						<input
							style={{
								display: 'none',
							}}
							accept={allowedFileTypes}
							id={name}
							multiple={allowMultipleFiles}
							type="file"
							disabled={id === null}
							onChange={selectFile}
						/>
						{file && !uploadOnce ? (
							<div>
								<Button
									size="small"
									color="primary"
									variant="contained"
									onClick={() => uploadSelectedFile(file)}
								>
									Click to Upload
								</Button>
								<Button
									onClick={() => setFile(null)}
									sx={{
										marginLeft: 2,
									}}
								>
									Cancel
								</Button>
							</div>
						) : !file ? (
							// eslint-disable-next-line jsx-a11y/label-has-associated-control
							<label htmlFor={name}>
								<Button
									size="small"
									color="secondary"
									variant="contained"
									component="span"
									disabled={
										id === null ||
										disabled ||
										disableSelectFile
									}
								>
									Select File
								</Button>
							</label>
						) : null}
					</>
				)}
			</Grid>
		</Grid>
	);
};
