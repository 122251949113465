import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import type { AppProps } from 'next/app';
import { Analytics as NextAnalytics } from '@vercel/analytics/react';
import Head from 'next/head';
import type { ComponentType, ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useRouter } from 'next/router';
// @hohm
import { Authenticate } from 'hohm-auth';
import { TThemeName, getTheme } from 'hohm-themes';
import { loadTranslations, t } from 'hohm-i18n';
import {
	TrackingConsent,
	TrackNextPageViews,
	LocalizationProvider,
} from 'hohm-ui';
import { Analytics } from 'hohm-utilities';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import {
	Theme,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
// @app
import PathValidator from '@app/PathValidator';
import { auth } from '@app/client';
// @styles
import '@app/styles/globals.css';
import '@app/styles/places.css';
import '@app/styles/style.css';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useStore } from '@app/store';
import WithCampaign from '@app/general-components/WithCampaign';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface IDefaultTheme extends Theme {}
}

type TNextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type TAppPropsWithLayout = AppProps & {
	Component: TNextPageWithLayout;
};

const MUI_PRO_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_PRO_KEY;
const { LAUNCHDARKLY_SDK_CLIENT_SIDE_ID } = process.env;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const App = ({ Component, pageProps }: TAppPropsWithLayout) => {
	const router = useRouter();
	const setUserUtmData = useStore((state) => state.setUserUtmData);
	const campaign = useStore((state) => state.campaign);

	if (typeof window !== 'undefined') {
		loadTranslations({
			host: window.location.hostname,
			query: router.query,
			campaign,
		});
	}

	React.useEffect(() => {
		if (MUI_PRO_LICENSE_KEY) {
			LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);
		}

		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side');

		if (jssStyles && jssStyles.parentElement) {
			jssStyles.parentElement.removeChild(jssStyles);
		}

		// Analytics

		const analyticsConfig = t('analytics');

		Analytics.init(analyticsConfig);
		Analytics.track(Analytics.Events.ClientAppLoaded);
	}, []);

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			const utmData = Analytics.getUTM(router.query);

			if (Object.keys(utmData).length > 0) {
				setUserUtmData(utmData);
			}
		}
	}, [JSON.stringify(router.query), router.pathname]);

	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout ?? ((page) => page);

	const themeName = t('theme') as TThemeName;
	const theme = getTheme(themeName, campaign);

	return (
		<>
			<Head>
				<title>Solar Marketplace</title>
				<link rel="manifest" href="/manifest.json" />
				<link
					rel="icon"
					type="image/png"
					href="https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/SocialMediaAppIconOrange.png"
				/>
				<link
					rel="icon"
					type="image/png"
					href="https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/SocialMediaAppIconOrange.png"
				/>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
				/>
			</Head>

			<WithCampaign>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<ToastContainer limit={3} />
						<LocalizationProvider>
							<QueryClientProvider client={queryClient}>
								{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
								<CssBaseline />
								{/* <GoogleTagManager /> */}
								<Authenticate
									reAuthenticateClient={
										auth.reAuthenticateClient
									}
								>
									<PathValidator>
										<>
											{getLayout(
												<Component {...pageProps} />
											)}
											{/* this component needs to be activated everywhere but does not make sense to be shown on authentication loading screen */}
											<TrackingConsent />
										</>
									</PathValidator>
								</Authenticate>
								<ReactQueryDevtools initialIsOpen={false} />
							</QueryClientProvider>
						</LocalizationProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			</WithCampaign>
			<NextAnalytics />
			<TrackNextPageViews />
		</>
	);
};

export default withLDProvider({
	clientSideID: LAUNCHDARKLY_SDK_CLIENT_SIDE_ID as string,
})(App as ComponentType<object>);
