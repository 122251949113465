"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var images_1 = require("../../images");
// en is the default language and all missing keys
// should fallback to here.
// Therefore, the translation object below acts as the
// default translations for the entire system.
// This object is not allowed to be a Partial<> like
// the other translations are.
// It should contain every available key with a sensible
// default value set.
// There are some keys that are globally optional. An
// example is the brand.poweredBy key. At the global
// level, brand.poweredBy is not used but may be used
// by locations or business partners.
var translation = {
    theme: 'default',
    showNoAuthFlow: true,
    brand: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'MFC',
        legalName: 'MFC, a division of Nedbank',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/NEDBANK_MFC_Primary%20endorsed%20identity_RGB_Eng_NoMargin.png',
    },
    financePartners: [
        {
            name: 'Nedbank',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
        {
            name: 'Ooba Homeloans',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/finance-partners/ooba.png',
        },
        {
            name: 'MFC',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/NEDBANK_MFC_Primary%20endorsed%20identity_RGB_Eng_NoMargin.png',
        },
        {
            name: 'Glint',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
        },
    ],
    poweredBy: {
        name: '',
        legalName: '',
        logo: '',
    },
    location: {
        countryCode: 'za',
        countryName: 'South Africa',
        gps: {
            LatLng: {
                lat: -28.86052,
                lng: 25.988082,
            },
            zoom: 14,
        },
        currency: {
            code: 'zar',
            symbol: 'R',
        },
        tax: {
            rate: 15,
            label: 'VAT',
            getTax: function (val) { return Math.round(val * 100 * 0.15) / 100; },
        },
    },
    pages: {
        client: {
            home: {
                heading: 'Welcome Hohm',
            },
        },
        login: {
            heading: 'Welcome back!',
        },
        signup: {
            heading: 'Claim your free solar offer now.',
            content: 'Generate your customised proposal in 4 minutes.',
            promoImage: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone%20%281%29.png',
            callUsNumber: '010 510 4449',
            callUsText: '',
            offerings: [
                {
                    id: 0,
                    heading: 'Say goodbye to load shedding',
                    content: "Hohm Energy will give you and your family peace of mind that you don't have to restructure your life around the inconvenience of load shedding.",
                },
                {
                    id: 1,
                    heading: 'Get the best price',
                    content: 'Hohm Energy has negotiated the best prices from product suppliers and solar installers to ensure the best value for you.',
                },
                {
                    id: 2,
                    heading: 'Your own energy advisor',
                    content: 'Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.',
                },
            ],
            showOptionsHarmburger: true,
            hideOffers: false,
            collaborationText: 'In collaboration with our trusted financial partners',
            showHomeLoanFinePrint: true,
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
                batterySolutions: {
                    title: 'Our Most Popular Subscription Plans Customised for You',
                },
                showFinanceOptions: true,
                footer: {
                    links: [
                        [
                            {
                                label: 'Become a partner',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/enterprise-partner"),
                            },
                            {
                                label: 'Become an installer',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/partners-solar-installers"),
                            },
                        ],
                        [
                            {
                                label: 'F.A.Qs',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/faq"),
                            },
                            {
                                label: 'How does solar works?',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/what-is-solar-pv"),
                            },
                        ],
                        [
                            {
                                label: 'POPIA Data Policy',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/terms-and-conditions"),
                            },
                        ],
                        [
                            {
                                label: 'Legal Policy',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/terms-and-conditions"),
                            },
                            {
                                label: 'Contact Us',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "/contact-hohm"),
                            },
                        ],
                    ],
                },
            },
            whyUseData: [
                {
                    icon: images_1.diamondIcon,
                    title: 'You get exceptional quality and the best value',
                    description: 'We only use the highest quality, most durable products, from solar panels to inverters to everything in between.',
                },
                {
                    icon: images_1.engineerIcon,
                    title: 'Only trained and vetted installers work on your home',
                    description: "This means you're guaranteed unmatched workmanship and peace of mind from start to finish.",
                },
                {
                    icon: images_1.handshakeIcon,
                    title: 'The major banks and insurers are our partners.',
                    description: "We work with South Africa's largest financial service providers to bring you the widest range of solar finance options available.",
                },
                {
                    icon: images_1.handgearIcon,
                    title: 'We hold ourselves accountable',
                    description: 'We stand behind every system installed and are with you throughout your entire solar journey - from installation through to ongoing maintenance.',
                },
                {
                    icon: images_1.quicktimeIcon,
                    title: "We'll install within two weeks",
                    description: "For every day that there is a delay, we'll pay you what you would have saved had your solar system been fully functional.",
                },
            ],
        },
        finance: {
            maintenanceText: 'Excluding maintenance, insurance & compliance.',
        },
    },
    supportedLoanBanks: [
        'Nedbank',
        'Standard Bank',
        'Absa',
        'Investec',
        'BetterBond',
        'Other',
    ],
    modules: {
        draftProposal: {
            enableAdditionalFields: false,
            enableRentalBanner: false,
            showRewardsAlert: false,
            defaultOM: false,
            monthlyBill: {
                usageMap: {
                    low: 470,
                    medium: 824,
                    high: 2100,
                },
            },
        },
    },
    packages: {
        hohmPdf: {
            customBookACall: false,
            hideBrandLogo: false,
            hideFinancePages: false,
            hideInvoicePage: false,
        },
    },
    // This variable is initialized as blank because it applies at either the campaign or partner level.
    // It refers to the banks that are whitelisted for the campaign.
    campaignPartners: [],
    apps: {
        client: {
            showExtraTopMenus: true,
        },
    },
    financeOptions: [
        {
            label: 'Solar or battery subscription',
            value: 'glint',
            default: true,
        },
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
    analytics: {
        tagManager: {
            trackingId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER || '',
        },
        partnerTagManager: {
            trackingId: '',
        },
        mixpanel: {
            token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '',
        },
    },
    kitsTotalPricing: {
        flashPlus: 94999,
        sparkPlus: 129999,
        glow: 194999,
        shine: 238999,
        flash: 64999,
        spark: 84999,
    },
    subscriptionOnly: false,
    buyOnly: false,
    buyLabel: 'Buy',
    hideHomeLoanAddition: false,
    hideSolarFinance: false,
    hideSubscribeBuyCTA: false,
    financeType: 'cash',
    useInvestecPricing: false,
    allowCallBooking: true,
};
exports.default = translation;
