import { StateCreator } from 'zustand/vanilla';
import { Application } from '@feathersjs/feathers/lib';
// queries
import { Api } from 'hohm-queries';
import { Analytics, logger } from 'hohm-utilities';
import { CampaignTypes } from 'hohm-types';

export interface IUIData {
	mode: 'light' | 'dark';
	campaign?: CampaignTypes.ICampaignRead;
}

export interface IUIActions {
	toggleMode: () => void;
	getCampaign: () => void;
}

export type TUIStore = IUIData & IUIActions;

export const createUiStore =
	<T extends TUIStore>(
		client: Application<any, any>
	): StateCreator<T, [], [], TUIStore> =>
	(set) => ({
		mode: 'light',
		toggleMode: () =>
			set((state) => ({
				...state,
				mode: state.mode === 'light' ? 'dark' : 'light',
			})),
		getCampaign: async () => {
			try {
				const api = new Api.default(client);
				const { campaign: campaignToken } = Analytics.getCampaign();

				const result = (await api.getAll('campaigns', {
					token: [campaignToken, 'public'],
					$loadEager: ['approvedKits', 'approvedPaymentOptions'],
				})) as unknown as { data: CampaignTypes.ICampaignRead[] };

				const campaign = result.data.length
					? result.data.find(
							(item) => item.token === campaignToken
						) || result.data.find((item) => item.token === 'public')
					: undefined;

				set((state) => ({
					...state,
					campaign,
				}));
			} catch (error: unknown) {
				logger.error({ error });
			}
		},
	});
