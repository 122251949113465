"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignQuerySchema = exports.campaignUpdateSchema = exports.campaignWriteSchema = exports.campaignReadSchema = exports.campaignSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
// @types
var organisation_types_1 = require("./organisation.types");
var payment_options_types_1 = require("./payment-options.types");
var product_kit_types_1 = require("./product-kit.types");
var campaign_constants_types_1 = require("./campaign-constants.types");
var loadEagerOptions = ['approvedKits', 'approvedPaymentOptions'];
var loadEagerSchema = typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], loadEagerOptions, true)));
exports.campaignSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    token: typebox_1.Type.String(),
    logo: typebox_1.Type.Optional(typebox_1.Type.String()),
    campaignMargin: typebox_1.Type.Optional(typebox_1.Type.Number()),
    campaignDiscount: typebox_1.Type.Optional(typebox_1.Type.Number()),
    theme: typebox_1.Type.Optional(typebox_1.Type.Object({
        primaryColor: typebox_1.Type.String(),
        secondaryColor: typebox_1.Type.String(),
        primaryContrastColor: typebox_1.Type.String(),
        secondaryContrastColor: typebox_1.Type.String(),
        textColor: typebox_1.Type.String(),
    })),
    campaignType: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], campaign_constants_types_1.CAMPAIGN_TYPE, true))),
    approvedKitIds: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    approvedPaymentOptionIds: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    active: typebox_1.Type.Boolean(),
    linkedSources: typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], campaign_constants_types_1.CAMPAIGNS, true))),
    organisationIds: typebox_1.Type.Array(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.campaignReadSchema = typebox_1.Type.Intersect([
    exports.campaignSchema,
    typebox_1.Type.Object({
        approvedKits: typebox_1.Type.Optional(typebox_1.Type.Array(product_kit_types_1.productKitReadSchema)),
        approvedPaymentOptions: typebox_1.Type.Optional(typebox_1.Type.Array(payment_options_types_1.paymentOptionReadSchema)),
        organisations: typebox_1.Type.Array(organisation_types_1.organisationReadSchema),
    }),
]);
exports.campaignWriteSchema = typebox_1.Type.Omit(exports.campaignReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
    'organisations',
    'approvedKits',
    'approvedPaymentOptions',
]);
exports.campaignUpdateSchema = typebox_1.Type.Partial(exports.campaignSchema, {
    $id: 'CampaignUpdate',
});
var campaignQueryProperties = typebox_1.Type.Partial(exports.campaignSchema, {
    $id: 'CampaignQuery',
});
exports.campaignQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(campaignQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
